<template>
 <v-container>
   <v-container class="white-bg">
     <v-col>
        <router-view />
      </v-col>
   </v-container>
 </v-container>
</template>

<script>
export default {

};
</script>

<style>

</style>
